import React, { Component, useEffect, useState } from "react";
import Loader from "./common/Loader";
import TableComponent from "./common/TableComponent";
import moment from "moment";
import DateBox from "./controls/DateBox";
import Transition from "./common/Transition";
import SearchBox from "./controls/SearchBox";
import * as constants from "../constants";
import { DateFormatter } from "./common/DateFormatter";
import { currencyFormat, multiselectFilter } from "./common/commonFns";
import MultiSelection from "./controls/MultiSelection";
import { AgentsRequests } from "../apiRequests/AgentsRequests";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

const CommissionDueView = () => {
  const [state, setState] = useState({
    hidden: "hidden",
    isLoaded: false,
    agentId: localStorage.getItem("agentId"),
    tableRows: [],
    tableEmpty: "hidden",
    firstRowIndex: 0,
    lastRowIndex: constants.NUMBEROFROWS - 1,
    filterOn: false,
    dataLength: 0,
    searchFieldValue: "",
    isOpen: false,
    expanded: "",
    background: "hidden",
    fromDate: "",
    toDate: "",
    commissionsDetailsData: [],
    commissionsDetailsDataCopy: [],
    allPrograms: [],
    allCampuses: [],
    allInvoiceNo: [],
    campusSelectedList: [],
    invoiceNoSelectedList: [],
    programsSelectedList: [],
  });

  useEffect(() => {
    setState((prevState) => ({ ...prevState, hidden: "", isLoaded: true }));

    let startDate = localStorage.getItem("t6StartDate");
    let endDate = localStorage.getItem("t6EndDate");

    setState((prevState) => ({
      ...prevState,
      fromDate: startDate,
      toDate: endDate,
    }));
  }, []);

  const feesPaidEnrolmentsFn = (startDate, endDate) => {
    agentsRequests.agentCommissionDue(startDate, endDate).then((result) => {
      if (result !== "error") {
        let filteredData = [];
        let combinedData = {};
        result.map((object) => {
          if (object) {
            combinedData.enrolmentID = object.enrolmentID;
            combinedData.commission = object.totalCommission;
            combinedData.agentInvoiceNo = object.agentInvoiceNo;
            combinedData.campus = object.campus;
            combinedData.enrolmentNo = object.enrolmentNo;
            combinedData.invoiceDate = object.studentInvoiceDate;
            combinedData.program = object.program;
            combinedData.studentName = object.studentName;
            combinedData.studentInvoiceNo = object.studentInvoiceNo;
            combinedData.studentPayment = object.studentReceiptAmount;

            filteredData.push({ ...combinedData });
          }
        });
        setState((prevState) => ({
          ...prevState,
          commissionsDetailsData: filteredData,
          commissionsDetailsDataCopy: filteredData,
          dataLength: filteredData.length,
        }));
      } else {
        setState((prevState) => ({ ...prevState, tableEmpty: "" }));
      }
    });
  };

  useEffect(() => {
    loadMultiSelectData();
  }, [
    state.commissionsDetailsData,
    state.commissionsDetailsDataCopy,
    state.dataLength,
  ]);

  const loadMultiSelectData = () => {
    let allPrograms = [];
    let allCampuses = [];
    let allInvoiceNo = [];

    let data = state.commissionsDetailsDataCopy;

    data.map((object) => {
      if (allPrograms.some((item) => object.program === item)) {
      } else {
        allPrograms.push(object.program);
      }
    });

    data.map((object) => {
      if (allCampuses.some((item) => object.campus === item)) {
      } else {
        allCampuses.push(object.campus);
      }
    });

    data.map((object) => {
      if (allInvoiceNo.some((item) => object.agentInvoiceNo === item)) {
      } else {
        allInvoiceNo.push(object.agentInvoiceNo);
      }
    });

    setState((prevState) => ({
      ...prevState,
      allPrograms: allPrograms,
      allCampuses: allCampuses,
      allInvoiceNo: allInvoiceNo,
    }));
  };

  const loadData = () => {
    let tableRows = [];
    let formatedRowData = {};
    let data = state.commissionsDetailsDataCopy;
    let firstRowIndex = state.firstRowIndex;
    let lastRowIndex = state.lastRowIndex;

    data = dataProcessor(data);

    let dataLength = data.length;
    if (dataLength < parseInt(firstRowIndex) + 1) {
      firstRowIndex = 0;
      lastRowIndex = constants.NUMBEROFROWS - 1;
    }

    if (data != "" && data != null) {
      data.map((object, index) => {
        if (index >= firstRowIndex && index <= lastRowIndex) {
          formatedRowData.ImgSrc =
            "https://ui-avatars.com/api/name=" +
            object.studentName +
            "/?background=random";
          formatedRowData.Label1 = object.studentName;
          formatedRowData.DescriptionClm1 =
            " EnrolmentNo:" + object.enrolmentNo;
          formatedRowData.Label2 = object.program;
          formatedRowData["Campus"] = object.campus;
          formatedRowData["Student Invoice Number"] = object.studentInvoiceNo;
          formatedRowData["Student Payment"] =
            "$" + currencyFormat(object.studentPayment);
          formatedRowData["Agent Invoice Number"] = object.agentInvoiceNo;

          let invoiceDate = moment(object.invoiceDate, "DD/MM/YYYY").format(
            "DD-MM-YYYY"
          );
          formatedRowData["Invoice Date"] = invoiceDate;
          formatedRowData.Value = "$" + currencyFormat(object.commission);

          tableRows.push({ ...formatedRowData });
        }
      });
    } else {
      //following line will unhide Nodata View
      setState((prevState) => ({ ...prevState, tableEmpty: "" }));
    }

    setState((prevState) => ({ ...prevState, tableRows: tableRows }));

    setState((prevState) => ({
      ...prevState,
      hidden: "hidden",
      isLoaded: false,
    }));
  };

  const renderTable = () => {
    let tableHeadings = [
      "Student Details",
      "Program",
      "Campus",
      "Student Invoice Number",
      "Student Payment",
      "Agent Invoice Number",
      "Invoice Date",
      "Commission",
      "",
    ];
    let tableRows = state.tableRows;

    if (tableRows.length >= 1) {
      return (
        <TableComponent
          tableHeadings={tableHeadings}
          tableRows={tableRows}
          filterOn={state.filterOn}
          numberOfElementsPerPage={constants.NUMBEROFROWS}
          dataLength={state.dataLength}
          setPageIndexFn={setPageIndexFn}
          firstRowIndex={state.firstRowIndex}
          lastRowIndex={state.lastRowIndex}
          clmTwoWidth={"w-1/4"}
        />
      );
    } else {
      return (
        <div
          className={
            state.tableEmpty + " text-gray-600 w-full flex justify-center mt-20"
          }
        >
          <h1>
            There is No Data Available to Display, please reset filters if any
            applied.
          </h1>
        </div>
      );
    }
  };

  const setPageIndexFn = (firstRowIndex, lastRowIndex) => {
    setState((prevState) => ({
      ...prevState,
      firstRowIndex: firstRowIndex,
      lastRowIndex: lastRowIndex,
    }));
  };

  const searchHandeler = () => {
    setState((prevState) => ({ ...prevState, value: searchValue }));
    var searchValue = document.getElementById("search").value;
    let data = state.commissionsDetailsData;

    if (searchValue != "") {
      data = data.filter((object) =>
        object.studentName.toUpperCase().includes(searchValue.toUpperCase())
      );
    }

    if (data.length === 0) {
      setState((prevState) => ({ ...prevState, tableEmpty: "" }));
    } else {
      setState((prevState) => ({ ...prevState, tableEmpty: "hidden" }));
    }

    setState((prevState) => ({
      ...prevState,
      commissionsDetailsDataCopy: data,
    }));
  };

  useEffect(() => {
    loadMultiSelectData();
  }, [state.commissionsDetailsDataCopy]);

  const dataProcessor = (data) => {
    let programsSelectedList = state.programsSelectedList;
    let campusSelectedList = state.campusSelectedList;
    let invoiceNoSelectedList = state.invoiceNoSelectedList;

    if (programsSelectedList != "") {
      data = multiselectFilter(data, programsSelectedList, "program");
    }
    if (campusSelectedList != "") {
      data = multiselectFilter(data, campusSelectedList, "campus");
    }
    if (invoiceNoSelectedList != "") {
      data = multiselectFilter(data, invoiceNoSelectedList, "agentInvoiceNo");
    }

    // following line of code will set dataLength
    setState((prevState) => ({ ...prevState, dataLength: data.length }));
    return data;
  };

  const filtersDropdown = () => {
    let expanded = state.expanded;
    if (expanded) {
      setState((prevState) => ({
        ...prevState,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isOpen: true,
        expanded: "yes",
        background: "",
      }));
    }
  };

  const handleDateBox = (e, name) => {
    let selectedDate = e;

    let fromDate = new Date(dateFormatter.formatterThree(state.fromDate));
    let toDate = new Date(dateFormatter.formatterThree(state.toDate));
    let sDate = new Date(dateFormatter.formatterThree(selectedDate));
    localStorage.setItem("dateRangeLabel6", "Custom Range");
    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sDate);
      let endDate = dateFormatter.formatterTwo(toDate);
      localStorage.setItem("t6StartDate", startDate);
      setState((prevState) => ({
        ...prevState,
        fromDate: startDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sDate);
      localStorage.setItem("t6EndDate", endDate);
      setState((prevState) => ({
        ...prevState,
        toDate: endDate,
        isOpen: false,
        expanded: "",
        background: "hidden",
      }));
    }
  };

  useEffect(() => {
    if (state.fromDate && state.toDate) {
      feesPaidEnrolmentsFn(state.fromDate, state.toDate);
    }
  }, [
    state.fromDate,
    state.isOpen,
    state.expanded,
    state.background,
    state.toDate,
  ]);

  const onMultiSelectChange = (selectedList, item, id) => {
    if (id === "program") {
      setState((prevState) => ({
        ...prevState,
        programsSelectedList: selectedList,
      }));
    } else if (id === "campus") {
      setState((prevState) => ({
        ...prevState,
        campusSelectedList: selectedList,
      }));
    } else if (id === "invoiceNo") {
      setState((prevState) => ({
        ...prevState,
        invoiceNoSelectedList: selectedList,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
      expanded: "",
      background: "hidden",
    }));
  };

  useEffect(() => {
    loadData();
  }, [
    state.allPrograms,
    state.allCampuses,
    state.allInvoiceNo,
    state.isOpen,
    state.expanded,
    state.background,
    state.firstRowIndex,
    state.lastRowIndex,
  ]);

  const renderAdvancedSearch = () => {
    return (
      <div>
        <div className="md:mt-5 mt-1 flex xl:justify-end">
          <span
            onClick={() => filtersDropdown()}
            className="  p-2 h-10 rounded-md bg-white border border-gray-300 text-gray-700 hover:bg-gray-200 cursor-pointer"
          >
            Advanced Search
          </span>
        </div>
        <Transition
          show={state.isOpen}
          enter="transition ease-in duration-300 transform"
          enterFrom="opacity-25 scale-0"
          enterTo="opacity-100 scale-100"
          leave="transition ease-out duration-500 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-25 scale-0"
        >
          <div className="origin-top right-0 absolute z-10 mb-8 w-full bg-gray-200">
            <div className="px-2 pt-4 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
              <div className={"mx-4 mb-4 bg-gray-100"}>
                <div className="pb-4">
                  <div
                    className={" text-gray-600 w-full flex justify-center mb-4"}
                  >
                    <h1 className="font-semibold">Filters</h1>
                  </div>
                  <div className=" grid grid-cols-6 gap-6">
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="program"
                        label="Select Programs"
                        selectedValues={state.programsSelectedList}
                        data={state.allPrograms}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "program"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "program"
                          )
                        }
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="campus"
                        label="Select Campuses"
                        selectedValues={state.campusSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "campus"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "campus"
                          )
                        }
                        data={state.allCampuses}
                        placeholder="Select"
                      />
                    </div>
                    <div className="md:py-3 col-span-6 md:col-span-3 ">
                      <MultiSelection
                        id="invoiceNo"
                        label="Select Invoice Number"
                        selectedValues={state.invoiceNoSelectedList}
                        onSelect={(selectedList, selectedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            selectedItem,
                            "invoiceNo"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          onMultiSelectChange(
                            selectedList,
                            removedItem,
                            "invoiceNo"
                          )
                        }
                        data={state.allInvoiceNo}
                        placeholder="Select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div className="">
          <span
            onClick={() => filtersDropdown()}
            className={
              state.background +
              " z-6 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-gray-400 opacity-75"
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="relative mt-6 align-middle min-h-full">
      <Loader id={"tl1"} loaded={state.isLoaded} hidden={state.hidden}></Loader>

      <div className="rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id={"search"}
            label={"Search Student Name"}
            svgType={"student"}
            placeholder={"Name"}
            value={state.value}
            searchHandler={() => {
              searchHandeler();
            }}
          />
        </div>

        <div className="xl:col-span-1">
          <DateBox
            id={"Pd1"}
            label="Invoice Date From"
            dependentOn={true}
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"FromDate"}
            value={state.fromDate ? new Date(state.fromDate) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Pd1"}
            label="Invoice Date Till"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"ToDate"}
            value={state.toDate ? new Date(state.toDate) : ""}
            //onChange={this.handleChange}
          />
        </div>
        <div className="xl:col-span-2">{renderAdvancedSearch()}</div>
      </div>
      <div>{renderTable()}</div>
    </div>
  );
};

export default CommissionDueView;
