import React, { useState, useEffect } from "react";
import moment from "moment";
import DataTile from "./DataTile";
import DateBox from "./controls/DateBox";
import SearchBox from "./controls/SearchBox";
import Loader from "./common/Loader";
import { AgentsRequests } from "../apiRequests/AgentsRequests";
import { DateFormatter } from "./common/DateFormatter";
import MultiSelection from "./controls/MultiSelection";
import { multiselectFilter } from "./common/commonFns";
import Transition from "./common/Transition";

const dateFormatter = new DateFormatter();
const agentsRequests = new AgentsRequests();

const eyeSVG = (
  <svg
    className="w-5 h-4"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    />
  </svg>
);
function CommunicationsView() {
  const [dataEmpty, setDataEmpty] = useState("hidden");
  const [hidden, setHidden] = useState("hidden");
  const [isLoaded, setIsLoaded] = useState(false);
  const [communicationsData, setCommunicationsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredCommunicationsData, setFilteredCommunicationsData] = useState([]);
  const [fromDate, setFromDate] = useState(localStorage.getItem("t3StartDate"));
  const [toDate, setToDate] = useState(localStorage.getItem("t3EndDate"));
  const [expanded, setExpanded] = useState("");
  const [isEmailOpen, setIsEmailOpen] = useState(false);
  const [emailExpanded, setEmailExpanded] = useState("");
  const [senderSearchvalue, setSenderSearchValue] = useState("");
  const [subjectSearchvalue, setSubjectSearchValue] = useState("");
  const [background, setBackground] = useState("hidden");
  const [emailbackground, setEmailBackground] = useState("hidden");
  const [emailData, setEmailData] = useState(<div></div>);
  const [typeSelectedList, setTypeSelectedList] = useState([]);
  const typeMsData = ["Email", "Contact Log", "SMS"];

  const communicationsDetailsFn = (startDate, endDate) => {
    agentsRequests.communcationsDetails(startDate, endDate).then((result) => {
      let responseData = result;
      if (responseData !== "error") {
        setCommunicationsData(responseData);
        setFilteredCommunicationsData(responseData);
        setHidden("hidden");
        setIsLoaded(false);
      } else {
        setDataEmpty("");
      }
    });
  };

  const searchHandeler = (id) => {
    let data = communicationsData;
    if (id === "sender") {
      var searchValue = document.getElementById("sender").value;
      setSenderSearchValue(searchValue);
      setSubjectSearchValue("");

      if (searchValue != "") {
        data = data.filter((object) =>
          object.from
            .replace("From: ", "")
            .toUpperCase()
            .includes(searchValue.toUpperCase())
        );
      }
    } else if (id === "subject") {
      var searchValue = document.getElementById("subject").value;
      setSenderSearchValue(senderSearchvalue);
      setSubjectSearchValue(searchValue);

      if (searchValue != "") {
        data = data.filter((object) =>
          object.subject
            .replace("Subject: ", "")
            .toUpperCase()
            .includes(searchValue.toUpperCase())
        );
      }
    }

    if (data.length === 0) {
      setDataEmpty("");
    } else {
      setDataEmpty("hidden");
    }

    setFilteredCommunicationsData(data);
  }

  const onMultiSelectChange = (selectedList, item, id) => {
    if (id === "type") {
      setTypeSelectedList(selectedList);
      RenderTiles();
    }
  }

  const showDataView = (from, subject, emailHtml, type) => {
    let emailData = (
      <div className="bg-gray-200">
        <p className="pt-2 text-green-700 text-md md:text-lg mb-4 text-center font-semibold">
          {type}
        </p>
        <div className="text-sm md:text-sm">
          <div className="pb-1 border-dotted border-b-2 border-gray-400">
            <p className="p-2 text-gray-900 ">{from}</p>
            <p className="p-2 mt-1  text-gray-900 ">{subject}</p>
          </div>
          <div
            className="p-4 mt-3 text-gray-700"
            dangerouslySetInnerHTML={{ __html: emailHtml }}
          ></div>
        </div>
      </div>
    );

    setIsEmailOpen(true);
    setEmailBackground("");
    setEmailData(emailData);
  }

  const closeDataView = () => {
    setIsEmailOpen(false);
    setEmailBackground("hidden");
    setEmailData(<div></div>);
  }

  const handleDateBox = (selectedDate, name) => {
    let fromdate = new Date(dateFormatter.formatterThree(fromDate));
    let todate = new Date(dateFormatter.formatterThree(toDate));
    let sdate = new Date(dateFormatter.formatterThree(selectedDate));

    if (name === "FromDate") {
      let startDate = dateFormatter.formatterTwo(sdate);
      let endDate = dateFormatter.formatterTwo(todate);
      localStorage.setItem("t3StartDate", startDate);
      localStorage.setItem("dateRangeLabel3", "Custom Range");
      setFromDate(selectedDate)
      setIsOpen(false)
      setExpanded("")
      setBackground("hidden")
      communicationsDetailsFn(startDate, endDate);

    } else if (name === "ToDate") {
      let startDate = dateFormatter.formatterTwo(fromDate);
      let endDate = dateFormatter.formatterTwo(sdate);
      localStorage.setItem("t3EndDate", endDate);
      localStorage.setItem("dateRangeLabel3", "Custom Range");
      setToDate(selectedDate)
      setIsOpen(false)
      setExpanded("")
      setBackground("hidden")
      communicationsDetailsFn(startDate, endDate);
    }
  }

  const dataProcessor = (data) => {
    if (typeSelectedList.length > 0) {
      data = multiselectFilter(data, typeSelectedList, "title");
    }
    return data;
  };

  const displayDate = (changeD, date) => {
    if (changeD) {
      let cdate = moment(changeD, "DD/MM/YYYY");
      return "Date: " + moment(cdate).format("DD-MM-YYYY");
    } else {
      return "Date: " + moment(date).format("DD-MM-YYYY");
    }
  }

  const RenderTiles = () => {
    let updatedcommunicationsData = filteredCommunicationsData;
    console.log(dataEmpty, "dataEmpty")
    updatedcommunicationsData = dataProcessor(updatedcommunicationsData);
    if (updatedcommunicationsData.length > 0) {
      return (
        <div className="bg-gray-100 h-70vh  shadow rounded-lg overflow-auto">
          <div className="p-4 mt-5 grid grid-cols-1 gap-5 md:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-4 ">
            {updatedcommunicationsData.map(({ changeDate, date, title, logType, from, message, subject }, index) => (
              <div key={"id" + index}>
                <DataTile
                  id={"id" + index}
                  svgType={title}
                  title={title}
                  description1={
                    <div className="text-xs text-gray-500">
                      <p>{displayDate(changeDate, date)}</p>
                      <p className={title === "Contact Log" ? "" : "hidden"}>
                        {"Type: " + logType}
                      </p>
                    </div>
                  }
                  description2={
                    <div className="text-xs">
                      <div className="">
                        <p> {from}</p>
                      </div>
                      <div className="">
                        <div
                          onClick={() =>
                            showDataView(
                              from,
                              "Date: " + displayDate(changeDate, date),
                              message,
                              title
                            )
                          }
                          className={title === "Email" ? "hidden" : ""}
                        >
                          <div className="grid grid-cols-7">
                            <div className="col-span-6 text-xs text-gray-500 truncate">
                              <p
                                className={
                                  title === "Email"
                                    ? "hidden"
                                    : "" + " truncate"
                                }
                              >
                                {message === "" ? "Empty" : message}
                              </p>
                            </div>
                            <div className="col-span-1 flex justify-end">
                              <div className="hover:text-blue-700 text-sm text-gray-600 hover:font-semibold cursor-pointer">
                                {eyeSVG}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            showDataView(
                              from,
                              subject,
                              message,
                              title
                            )
                          }
                          className={title === "Email" ? "" : "hidden"}
                        >
                          <div className="grid grid-cols-7">
                            <div className="col-span-6 text-xs text-gray-500 truncate">
                              <p>{subject}</p>
                            </div>
                            <div className="col-span-1 flex justify-end">
                              <div className="hover:text-blue-700 text-sm text-gray-600 hover:font-semibold cursor-pointer">
                                {eyeSVG}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className={dataEmpty + " text-gray-600 w-full flex justify-center mt-20"}>
          <h1>
            There is No Data Available to Display, please reset filters if any applied.
          </h1>
        </div>
      );
    }
  };

  useEffect(() => {
    setHidden("");
    setIsLoaded(true);
    let startDate = dateFormatter.formatterTwo(localStorage.getItem("t3StartDate"));
    let endDate = dateFormatter.formatterTwo(localStorage.getItem("t3EndDate"));
    communicationsDetailsFn(startDate, endDate);
  }, []);

  useEffect(() => {
    setDataEmpty("")
  }, [dataEmpty])

  return (
    <div className="relative mt-6 align-middle">
      <span
        className={
          hidden +
          " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-white opacity-50"
        }
      />
      <Loader loaded={isLoaded} id={"communicationview"} hidden={hidden} />

      <div className="flex rounded-md p-4 mb-4 md:mb-8 w-full grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-6 bg-gray-100">
        <div className="xl:col-span-2">
          <SearchBox
            id={"sender"}
            label={"Search For Sender's Name"}
            svgType={"student"}
            placeholder={"Name"}
            value={senderSearchvalue}
            searchHandler={() => {
              searchHandeler("sender");
            }}
          />
        </div>
        <div className="xl:col-span-2">
          <SearchBox
            id={"subject"}
            label={"Search For Email Subject"}
            svgType={"textFile"}
            placeholder={"Subject"}
            value={subjectSearchvalue}
            searchHandler={() => {
              searchHandeler("subject");
            }}
          />
        </div>
        <div className="xl:col-span-2 ">
          <MultiSelection
            id="type"
            label="Select Communication Type"
            selectedValues={typeSelectedList}
            data={typeMsData}
            onSelect={(selectedList, selectedItem) =>
              onMultiSelectChange(selectedList, selectedItem, "type")
            }
            onRemove={(selectedList, removedItem) =>
              onMultiSelectChange(selectedList, removedItem, "type")
            }
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Ud1"}
            label="Date From"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"FromDate"}
            value={fromDate ? new Date(fromDate) : ""}
          />
        </div>
        <div className="xl:col-span-1">
          <DateBox
            dependentOn={true}
            id={"Ud1"}
            label="Date Till"
            //minDate={moment().add(0, "days").toDate()}
            onChange={handleDateBox}
            name={"ToDate"}
            value={toDate ? new Date(toDate) : ""}
          //onChange={handleChange}
          />
        </div>
      </div>
      <Transition
        show={isEmailOpen}
        enter="transition ease-in duration-300 transform"
        enterFrom="opacity-25 scale-0"
        enterTo="opacity-100 scale-100"
        leave="transition ease-out duration-500 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-25 scale-0"
      >
        <div className="z-40 origin-top Data-View">
          <div className="p-2 lg:mt-0 flex-shrink-0 bg-gray-100 sm:rounded-lg border-b border-gray-200">
            <div className={" bg-gray-100"}>
              <div>{emailData}</div>
            </div>
          </div>
        </div>
      </Transition>
      <div className="">
        <span
          onClick={() => closeDataView()}
          className={
            emailbackground +
            " z-30 fixed top-10 left-0 right-0 bottom-0 h-full w-full bg-black opacityBackground"
          }
        />
      </div>

      {RenderTiles()}
    </div>
  );
}

export default CommunicationsView;
