import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import AgentDashboard from "./components/AgentDashboard";
import PendingProposals from "./components/PendingProposals";
import OverDues from "./components/OverDues";
import ActiveEnrolments from "./components/ActiveEnrolments";
import DocumentsView from "./components/DocumentsView";
import PaymentRecordView from "./components/PaymentRecordView";
import CommunicationsView from "./components/CommunicationsView ";
import FutureCommission from "./components/FutureCommissionView";
import CommissionDueView from "./components/CommissionDueView";
// import WebForm from "./components/WebForm";

export const DashboardRoutes = () => (
  <Routes>
    <Route path="/PendingApplications" element={<PendingProposals />} />
    <Route path="/OverDues" element={<OverDues />} />
    <Route path="/commissionDue" element={<CommissionDueView />} />
    
    <Route path="/FutureCommission" element={<FutureCommission />} />

    <Route path="/paidCommissionsView" element={<PaymentRecordView />} />
    <Route path="/documents" element={<DocumentsView />} />
    <Route path="/communications" element={<CommunicationsView />} />
    <Route path="/" element={<AgentDashboard />} />
    <Route path="/ActiveEnrolments" element={<ActiveEnrolments />} />
  </Routes>
);

export const RoutesPath = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/dashboard/*" element={<Dashboard />} />

    {/* <Route path="/webform/:id" component={WebForm} /> */}
    <Route path="/" element={<Login />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
